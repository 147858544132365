import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuth } from '../../context/AuthContext';
import LatMain from '../Latitud/LatMain';
import DashboardTenaris from '../Tenaris/DashboardTenaris';
import SingleView from '../SingleView';
import LatOne from '../Latitud/LatOne';

import LogoQM from '../../QM - blanco.png';
import fotoQM from '../../assets/Prometheus.png';

import LogoTenaris from '../../assets/LogoTenaris.png';


import useIsMobile from "./useIsMobile";
import { isMobile } from 'react-device-detect';

const Dashboard = () => {
    const {currentUser} = useAuth();
    const navigate = useNavigate()

    const stylesLatitud = {
        backgroundNavBar: '#32413F',
        LogoNavBar: LogoQM,
        LogoNavBarHeight: "8.5vh",
        LogoNavBarMarginBottom: "2.5vh",
        backgroundCards:"rgb(247, 248, 250)",
        Foto: fotoQM,
    }

    const stylesQM = {
        backgroundNavBar: '#32413F',
        LogoNavBar: LogoQM,
        LogoNavBarHeight: "8.5vh",
        LogoNavBarMarginBottom: "2.5vh",
        backgroundCards:"rgb(247, 248, 250)",
        Foto: fotoQM,
    }


    useEffect(() => {
        if (!currentUser?.company) return; // Evitar ejecución si no hay usuario
        
        let targetPath = "";
        let customStyles = {};
    
        switch (currentUser.company) {
            case "QM":
                targetPath = isMobile ? "/dashboard/m/QM" : "/dashboard/QM";
                customStyles = stylesQM;
                break;
            case "Tenaris":
                console.log("Entro tenaris")
                console.log("MObile:" ,isMobile)
                targetPath = isMobile ? "/dashboard/m/Set/" : "/dashboard/Set";
                break;
            default:
                return;
        }
    
        // Evitar múltiples redirecciones si ya estamos en la ruta correcta
        if (window.location.pathname !== targetPath) {
            navigate(targetPath, { state: { customStyles }, replace: true });
        }
    
    }, [navigate, currentUser?.company, isMobile]);

    // Función para renderizar el componente según la empresa y el tamaño de pantalla
    const renderCompanyComponent = () => {
        if (!currentUser?.company) return <p>Company not recognized</p>;
    
        switch (currentUser.company) {
            case "Latitud":
                return isMobile ? <LatMain customStyles={stylesLatitud} /> : <LatOne customStyles={stylesLatitud} />;
            default:
                return null;
        }
    };

    return (
        <div>
         {renderCompanyComponent()}
        </div>
    );
};

export default Dashboard;