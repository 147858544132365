import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { database,  GetEquipmentDataForDay,  observer } from '../../../server/firebase'
import Footer from '../../Footer';
import NavBarGeneric from '../../NavBarGeneric';
import styles from './DashboardGraf.module.css';
import LatTitle from '../LatTitle';
import ScatterChart from './ScatterChart';
import { useAuth } from '../../../context/AuthContext';
import { ref, set } from 'firebase/database';
import LatCardGraf from '../LatCardGraf';
import Filters from './Filters';
import Chart from './Chart';
import FilterPanel from './FilterPanel';
import { isMobile } from 'react-device-detect';
import DataExport from './DataExport';

const DashboardGraf = () => {
    const [fracs, setFracs] = useState([])
    const [properties, setProperties] = useState([])
    const [dataEnable, setDataEnable] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState([])
    const [var1, setVar1] = useState([])
    const [var2, setVar2] = useState([])
    const {currentUser} = useAuth()
    const [filtros, setFiltros] = useState({
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
    });
    const [dataEnable2Download, setDataEnable2Download] = useState(false);

    const Location = useLocation();
    const customStyles = Location.state?.customStyles || {}; // Asegura que no sea undefined
    console.log("Estilos recibidos en GRAF:", customStyles.LogoNavBarHeight);

    // Función que maneja los cambios en los filtros
    const handleFilterChange = (nuevosFiltros) => {
        setFiltros(nuevosFiltros);
    };

    useEffect(() => {
        // Configura Firebase con la información de tu proyecto
        const companyRef = ref(database, `/BullBox/Companies/${currentUser.company}`)
        observer(companyRef, (snapshot) => {
            const data = snapshot.val();
            if (data) 
                {
                    const fracs = Object.keys(data)
                    setFracs(fracs); 
                    const properties = Object.keys(Object.values(data)[0].Data).filter((property) => property !== "Name")
                    setProperties(properties)
                    setDataEnable(true)
                } else {setDataEnable(false)}
            
        })
       
        // Detiene el escuchador cuando el componente se desmonta
        // return () => fracturadorRef.off('value');
      }, []);
      const handleApplyFilters = async (Equipment, col1, col2, date1, date2) => { 
        setVar1(col1)
        setVar2(col2)
        try {
            setDataLoading(true)
            let response = await GetEquipmentDataForDay(currentUser, Equipment, date1, date2) 
            setData(response)
            setDataLoading(false)
        } catch (error) {
            setDataLoading(false)
            console.log('Failed to get data')
        }
      };

      useEffect(() =>  {
        if (data.length > 10) {
            setDataEnable2Download(true);
        } else {
            setDataEnable2Download(false);
        }
      },[data])
    return (
        <>
        <div className={styles.screen}>
            <NavBarGeneric customStyles={customStyles} linkHome={"/dashboard"} linkDash={"/graph"}/>
            <LatTitle title={"Prometheus"} subtitle={"GRÁFICO"}/>
            <div className={styles.cardsContainerGraf}>
                {dataEnable && <FilterPanel equipments={fracs} variables={properties} onApplyFilters={handleApplyFilters}  />}
                {dataEnable2Download && data.length > 10 && <DataExport data={data} />}
                <div className={`${styles.Graf} ${isMobile ? styles.mobile : ""}`}>
                    {data.length > 10 ? <Chart data={data} var1={var1} var2={var2}/> : <div> {dataLoading ? <p>Cargando datos...</p> : <p>No hay datos cargados</p>  } </div>}
                </div>
            </div>
            <Footer />

        </div>
        </>
    )
}

export default DashboardGraf;