import React from "react";
import styles from './LatMonitorValue.module.css'

const LatMonitorValue = (props) => {
    return (
        <div className={styles.container}>
            <p className={styles.value}>{props.value}</p>
            <p className={styles.unity}>{props.unity}</p>
        </div>
    )
}

export default LatMonitorValue;