import React from "react";
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import icon from "../../../assets/gps.png";
import equipo from "../../../assets/Prometheus.png";


const customIcon = new L.Icon({
    iconUrl: icon, // Ruta correcta
    iconSize: [52, 50], // Tamaño original del icono
    iconAnchor: [28, 50], // Punto donde se coloca el icono en el mapa
    
  });

const Map = ({ showMarker=true ,lat = -38.002714, lng = -57.668606, H="65vh", w="100%", z=10}) => {
    const circleCenter = [lat, lng];
  return (
    <MapContainer center={[lat, lng]} zoom={z} zoomControl={false}
                   attributionControl={false}
                   dragging={showMarker} 
                    scrollWheelZoom={showMarker} 
                    doubleClickZoom={showMarker} 
                    touchZoom={showMarker}
                    keyboard={showMarker}
                   style={{ height: H, width: w , borderRadius: "25px"}}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {showMarker && (
        <Marker position={[lat, lng]} icon={customIcon} style={{  }} >
        <Popup>
          <div style={{ textAlign: "center" }}>
            <img 
              src = {equipo}  
              style={{ width: "100%", borderRadius: "5px" }} 
            />
          </div>
        </Popup>
      </Marker>
      )}
      

      
      
    </MapContainer>
  );
};

export default Map;

