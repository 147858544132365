import React, { useState } from 'react'
import { Link, useLocation, useRoutes } from 'react-router-dom';
import styles from './style.module.css'
import image from '../../QM - blanco.png'

export default function Header() {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState(location.pathname); 
    const handleTabClick = (tab) => setActiveTab(tab);

  return (
    <nav className={styles.navbar}>
        <a href='https://www.qm-equipment.com/' className={styles.a}> 
                <img src={image} className={styles.img} />
        </a>
        <ul >
            <li onClick={() => handleTabClick("/home")} className={activeTab === "/home" ? styles.active : ""} style={{padding: '10px'}}>
                <Link to={'/home'} style={{padding: '0px 1vw'}}>Home</Link>
            </li>
            <li onClick={() => handleTabClick("/about")} className={activeTab === "/about" ? styles.active : ""} style={{padding: '10px'}}>
                <Link to={'/about'} style={{padding: '0px 1vw'}}>About</Link>
            </li>
        </ul>
        <ul style={{marginRight:"6vw"}}>       
            <li onClick={() => handleTabClick("/login")} className={activeTab === "/login" ? styles.active : ""} style={{padding: '10px'}}>
                <Link to={'/login'} >Log In</Link>
            </li>
        </ul>
    </nav>
  )
}
