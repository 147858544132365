import React, { useEffect, useState } from 'react';
import { fracturadorRef, observer } from '../../server/firebase'

import CardFrac from '../../components/Tenaris/CardFrac';
import BlenderCard from '../../components/Tenaris/BlenderCard';
import Card from '../../components/Latitud/LatMain/CardFrac/Insight/Insight'
import Title from '../../components/Title';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from './SetMobile.module.css'

const SetMobile = () => {
  const [cleanFracsData, setCleanFracsData] = useState([]);
  const [dirtyFracsData, setDirtyFracsData] = useState([]);
  const [blender, setBlender] = useState([])
  const [dataset, setDataSet] = useState({})
  const [type, setType ] = useState(1)
  

  useEffect(() => {

    // Configura Firebase con la información de tu proyecto
    observer(fracturadorRef, (snapshot) => {
      const data = snapshot.val();
      // console.log(data)
      // const dataArray = Object.values(data);
      if (data.Setlimpio) setCleanFracsData(data.Setlimpio)
      if (data.Setsucio) setDirtyFracsData(data.Setsucio)
      if (data.Blender) setBlender(Object.entries(data.Blender))
      if (data.Informacion) setDataSet(data.Informacion)
      // setBlender(Object.entries(dataArray[0]))
    })
   
    // Detiene el escuchador cuando el componente se desmonta
    // return () => fracturadorRef.off('value');
  }, []);
  
  const ChangeStatus = () => {
    // e.preventDefault()
    if (type === 1) setType(2)
    else setType(1)
  }

  useEffect(() => {
    const pagination = document.querySelector(".swiper-pagination");
    if (pagination) {
        pagination.style.left = "50%";
        pagination.style.transform = "translateX(-50%)";
        pagination.style.top  = '-4%';
        pagination.style.overflow = 'Clip';
        pagination.style.overflowY = 'Visible';
        pagination.style.zIndex = "0";

        const paginationBullets = document.querySelectorAll(".swiper-pagination-bullet");
        paginationBullets.forEach(bullet => {
            bullet.style.width = 'var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 6px))';
        });
    }
}, []);

  // console.log(blender)
  // console.log(dataset)
  // console.log(type)
  return (
    <div className={styles.container}>
      <NavBar changeSet={ChangeStatus}/>
      <div>
        <Title  deposit={dataset.Yacimiento} location={dataset.Locacion} etapa={dataset.Etapa} well={dataset.TipoDePozo} operator={dataset.Cliente} />
      </div>
      <div className={styles.InsightContainer}>
        {blender.slice(0, 3).map((property, i) => (
        <BlenderCard
          
          key={i} 
          title={property[0].toUpperCase()} 
          value={property[1].Value} 
          unity={property[1].Unity} 
        />
))}
      </div>
      <div className={styles.cardsContainer}>

      <Swiper style={{ overflow: 'visible', position: 'relative', "--swiper-pagination-color": "#039801" }}
        spaceBetween={10}
        slidesPerView={3} // Default: 1 por vista
        freeMode={true}
        pagination={{ dynamicBullets: true, }}
        modules={[Pagination]}
        
      >
        {type === 1
          ? cleanFracsData.slice(0, 10).map((fracturador, index) => ( 
              <SwiperSlide style={{width:'auto!important', overflow: 'visible!important',
                willChange: 'transform!important'}} key={index}>
                <CardFrac 
                  name={fracturador.name}
                  engineRPM={fracturador.engineRPM}
                  transmissionCurrentGear={fracturador.transmissionCurrentGear}
                  pumpDischargePressure={fracturador.pumpDischargePressure}
                  pumpSwitchPressure={fracturador.pumpSwitchPressure}
                  pumpFlowRate={fracturador.pumpFlowRate}
                  engineLoadFactor={fracturador.engineLoadFactor}
                  pumpChargePressure={fracturador.pumpChargePressure}
                  voltage={fracturador.voltage}
                  transmissionLockup={fracturador.transmissionLockup}
                />
              </SwiperSlide>
            ))
          : dirtyFracsData.slice(0, 10).map((fracturador, index) => ( 
              <SwiperSlide style={{width:'auto!important' }} key={index}>
                <CardFrac
                  name={fracturador.name}
                  engineRPM={fracturador.engineRPM}
                  transmissionCurrentGear={fracturador.transmissionCurrentGear}
                  pumpDischargePressure={fracturador.pumpDischargePressure}
                  pumpSwitchPressure={fracturador.pumpSwitchPressure}
                  pumpFlowRate={fracturador.pumpFlowRate}
                  engineLoadFactor={fracturador.engineLoadFactor}
                  pumpChargePressure={fracturador.pumpChargePressure}
                  voltage={fracturador.voltage}
                  transmissionLockup={fracturador.transmissionLockup}
                />
        </SwiperSlide>
      ))
  }
</Swiper>
      </div>
      <Footer />
    </div>
  );
};

export default SetMobile;
