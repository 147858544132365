import React, { useEffect, useState } from 'react';
import { fracturadorRef, observer } from '../../../server/firebase'
import styles from './DashboardTenaris.module.css'
import CardFrac from '../CardFrac';
import BlenderCard from '../BlenderCard';
import Title from '../../Title';
import NavBar from '../../NavBar';
import Footer from '../../Footer';


const DashboardTenaris = () => {
  const [cleanFracsData, setCleanFracsData] = useState([]);
  const [dirtyFracsData, setDirtyFracsData] = useState([]);
  const [blender, setBlender] = useState([])
  const [dataset, setDataSet] = useState({})
  const [type, setType ] = useState(1)

  useEffect(() => {
    // Configura Firebase con la información de tu proyecto
    observer(fracturadorRef, (snapshot) => {
      const data = snapshot.val();
      // console.log(data)
      // const dataArray = Object.values(data);
      if (data.Setlimpio) setCleanFracsData(data.Setlimpio)
      if (data.Setsucio) setDirtyFracsData(data.Setsucio)
      if (data.Blender) setBlender(Object.entries(data.Blender))
      if (data.Informacion) setDataSet(data.Informacion)
      // setBlender(Object.entries(dataArray[0]))
    })
   
    // Detiene el escuchador cuando el componente se desmonta
    // return () => fracturadorRef.off('value');
  }, []);
  
  const ChangeStatus = () => {
    // e.preventDefault()
    if (type === 1) setType(2)
    else setType(1)
  }

 

  // console.log(blender)
  // console.log(dataset)
  // console.log(type)
  return (
    <div className={styles.container}>
      <NavBar changeSet={ChangeStatus}/>
      <div>
        <Title  deposit={dataset.Yacimiento} location={dataset.Locacion} etapa={dataset.Etapa} well={dataset.TipoDePozo} operator={dataset.Cliente} />
      </div>
      <div className={styles.cardsContainer}>
        {blender.map((property,i) => (<BlenderCard key={i} title={property[0].toUpperCase()} value={property[1].Value} unity={property[1].Unity}/>))}
      </div>
      <div className={styles.cardsContainer}>
        {type === 1 ? cleanFracsData?.map((fracturador, index) => ( 
          <CardFrac key={index} name={fracturador.name} engineRPM={fracturador.engineRPM} transmissionCurrentGear={fracturador.transmissionCurrentGear} pumpDischargePressure={fracturador.pumpDischargePressure}
          pumpSwitchPressure={fracturador.pumpSwitchPressure} pumpFlowRate={fracturador.pumpFlowRate} engineLoadFactor={fracturador.engineLoadFactor} pumpChargePressure={fracturador.pumpChargePressure}
          voltage={fracturador.voltage} transmissionLockup={fracturador.transmissionLockup}
          />
          )) : dirtyFracsData?.map((fracturador, index) => ( 
            <CardFrac key={index} name={fracturador.name} engineRPM={fracturador.engineRPM} transmissionCurrentGear={fracturador.transmissionCurrentGear} pumpDischargePressure={fracturador.pumpDischargePressure}
            pumpSwitchPressure={fracturador.pumpSwitchPressure} pumpFlowRate={fracturador.pumpFlowRate} engineLoadFactor={fracturador.engineLoadFactor} pumpChargePressure={fracturador.pumpChargePressure}
            voltage={fracturador.voltage} transmissionLockup={fracturador.transmissionLockup}
            />
            ))}
      </div>
        
      <Footer />
    </div>
  );
};

export default DashboardTenaris;
