import React from 'react';

import { useAuth } from '../../context/AuthContext';
import DashboardGraf from '../Latitud/DashboardGraf';

const Graph = () => {
    const {currentUser} = useAuth();
    const renderCompanyComponent = () => {
        switch (currentUser.company) {
            case 'Latitud':
                return <DashboardGraf />;
            case 'QM':
                return <DashboardGraf />;
            case 'Tenaris':
                return <DashboardGraf />;
            default:
                return <p>Company not recognized</p>;
        }
    };

    return (
        <div>
         {renderCompanyComponent()}
        </div>
    );
};

export default Graph;