import { useEffect, useState } from "react";
import styles from "./FilterPanel.module.css";
import TimeFilter from "../TimeFilter/index.jsx";
import { isMobile } from "react-device-detect";
import { getNameByKey, getTodayDate, getYesterdayDate } from "../../../../utils/index.js";
import { dictionaryVariableNames } from "../../../../resources/index.js";
import { get } from "firebase/database";


const FilterPanel = ({ equipments, variables, onApplyFilters }) => {
    const [selectedEquipment, setSelectedEquipment] = useState(equipments[0] || "");
    const [selectedVar1, setSelectedVar1] = useState(variables[0] || "");
    const [selectedVar2, setSelectedVar2] = useState(variables[1] || "");
    const [startDate, setStartDate] = useState("2025-03-06");
    const [endDate, setEndDate] = useState("2025-03-07");
    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("23:59");

    const handleApply = () => {
        
        onApplyFilters(selectedEquipment, selectedVar1, selectedVar2, new Date(`${startDate}T${startTime}`), new Date(`${endDate}T${endTime}`));
    };

    const handleTimeChange = (startDate, endDate, startTime, endTime) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setStartTime(startTime);
        setEndTime(endTime);
    };

    useEffect(() => {
        setStartDate(getYesterdayDate());
        setEndDate(getTodayDate());
    },[]);
    
    return (
        <div className={`${styles.container} ${isMobile ? styles.mobile : ""}`}>
            <div className={`${styles.filterPanelContainer} ${isMobile ? styles.mobile : ""}`}>
                <div className={`${styles.filterPanel} ${isMobile ? styles.mobile : ""}`}>
                    <div className={styles.filterPanelChill}>
                        <label>Selecciona un equipo:</label>
                        <select className={`${styles.filterOptions} ${isMobile ? styles.mobile : ""}`} value={selectedEquipment} onChange={(e) => setSelectedEquipment(e.target.value)}>
                            {equipments.map((Equipment) => (
                                <option key={Equipment} value={Equipment}>{Equipment}</option>
                            ))}
                        </select>
                    </div>
                            
                    <div className={styles.filterPanelChill}>
                        <label>Selecciona la primera variable:</label>
                        <select className={`${styles.filterOptions} ${isMobile ? styles.mobile : ""}`} value={selectedVar1} onChange={(e) => setSelectedVar1(e.target.value)}>
                            {variables.map((variable) => (
                                <option key={variable} value={variable}>{getNameByKey(variable, dictionaryVariableNames)}</option>
                            ))}
                        </select>
                    </div>

                    <div className={styles.filterPanelChill}>
                        <label>Selecciona la segunda variable:</label>
                        <select className={`${styles.filterOptions} ${isMobile ? styles.mobile : ""}`} value={selectedVar2} onChange={(e) => setSelectedVar2(e.target.value)}>
                            {variables.map((variable) => (
                                <option key={variable} value={variable}>{getNameByKey(variable, dictionaryVariableNames)}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <TimeFilter startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} startTime={startTime} endTime={endTime} setStartTime={setStartTime} setEndTime={setEndTime} />
            </div>
            <button className={`${styles.btn} ${isMobile ? styles.mobile : ""}`} onClick={handleApply}>Aplicar Filtros</button>
        </div>
    );
};

export default FilterPanel;
