export const getYesterdayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); // Restar un día
    return yesterday.toISOString().split("T")[0]; // Obtener solo la parte de la fecha
  };

export const getTodayDate = () => {
const today = new Date();
return today.toISOString().split("T")[0]; // Obtener solo la parte de la fecha
};

export const getNameByKey = (key, dictionary) => {
return dictionary[key] || key;
};
