import logo from '../../QM_logo.png';
import styles from './style.module.css';
import Layout from '../Layout';

export default function Home() {
  return (
    <Layout >

    
        <img src={logo} className={styles.logo} alt="logo"  />
        
        
    </Layout>
   
  );
}


