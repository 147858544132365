import React from "react";
import styles from './LatCardBomba.module.css'
import MonitorValue from "../LatMonitorValue";
import LatData from "../LatData";
import LatDatamini from "../LatDatamini";

const LatCardBomba = (props) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: props.background}}>
            <h1>BOMBA</h1>
            <div className={styles.rec}></div>
            <div className={styles.containerchild}>
                <LatData title={"Presión succión"} value={props.PumpChargePressure} unit={"PSI"}/>
                <LatData title={"Trip"} value={props.PumpSwitchPressure} unit={"PSI"}/>
            </div>
            <div className={styles.containerLub}>
                <a>Lubricación</a>
                <div className={styles.containerLubchild}>
                    <div className={styles.containerLubchild2}>
                        <div className={styles.recLub}></div>
                        <LatDatamini title={"Presión"} value={props.PumpLPPressure} unit={"PSI"}/>
                    </div>
                    <div className={styles.containerLubchild2}>
                        <div className={styles.recLub}></div>
                        <LatDatamini title={"Temperatura"} value={props.PumpLPTemperature} unit={"°C"}/>
                    </div>
                </div>
            </div>
        </div>
    )
} 

export default LatCardBomba;