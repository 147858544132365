import React from "react";
import styles from './Foto.module.css'
import { Link, useNavigate } from "react-router-dom";

const Foto = ({ customStyles = {}, linkFrac, name}) => {
    console.log(customStyles);
    return (
        <Link className={styles.container}>
            
                <div className={styles.fondo}>
                    <img src={customStyles.Foto} style={{ width: customStyles.FotoWidthMobile}} className={styles.image} />
                    <h1>{name}</h1>
                </div>
            
        </Link>
    )
} 

export default Foto;