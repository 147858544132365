import React, { useEffect, useState } from 'react';
import { database,  observer } from '../../../server/firebase'
import { useAuth } from '../../../context/AuthContext';
import { ref } from 'firebase/database';

import styles from './LatMain.module.css'
import NavBarGeneric from '../../NavBarGeneric';
import LatTitle from '../LatTitle';
import CardFrac from './CardFrac/CardFrac';
import Footer from '../../Footer';
import { getLocationName } from '../../locationService';
import Map from '../LatOne/Map';
import contenedorScroll from "../LatOne/LatOne.module.css";


import { useLocation } from "react-router-dom";

{/* PANTALLA MOBILE */}

const LatMain = ({customStyles = {}}) => {
    
    const [fracsData, setFracsData] = useState([])
    const {currentUser} = useAuth()
    const [ , setLocation] = useState("Cargando...");

    const location = useLocation();
    const stylesUser = location.state?.customStyles || {}; // Asegura que no sea undefined
    console.log("Estilos recibidos en LatMain:", stylesUser.LogoNavBarHeight);

    useEffect(() => {
            async function fetchLocation() {
                const name = await getLocationName(-38.002714, -57.668606);
                setLocation(name);
            }
            fetchLocation();
        }, []);

    useEffect(() => {
        // Configura Firebase con la información de tu proyecto
        const companyRef = ref(database, `/BullBox/Companies/${currentUser.company}`)
    
        observer(companyRef, (snapshot) => {
            const data = snapshot.val();
    
            if (data) 
                {
                    const fracs = Object.values(data)
                    setFracsData(fracs); 
                }
            
        })
       
      }, []);

      // Verifica que `fracsData` no esté vacío y que `fracsData[0].Data` exista antes de acceder
          const hasData = fracsData.length > 0 && fracsData[0]?.Data;
      
          // Si no hay datos aún, usa valores por defecto (evita el error de undefined)
          const defaultData = {
              PumpDischargePressure: 0,
              PumpFlowRate: 0,
              EngineRPM: 0,
              TransmissionCurrentGear: 0,
              Voltage: 0,
              TransmissionLockup: 0,
              TransmissionLUBTemperature: 0,
              EnginePressure: 0,
              EngineLoadFactor: 0,
              EngineTemperature: 0,
              PumpChargePressure: 0,
              PumpSwitchPressure: 0,
              PumpLPPressure: 0,
              PumpLPTemperature: 0,
              Name: "Sin datos"
          };
      
          const data = hasData ? fracsData[0].Data : defaultData;
      
          const useSmoothValue = (targetValue, smoothingFactor = 0.75) => {
              const [smoothedValue, setSmoothedValue] = useState(targetValue);
      
              useEffect(() => {
                  const interval = setInterval(() => {
                      setSmoothedValue((prev) => prev + (targetValue - prev) * smoothingFactor);
                  }, 100);
      
                  return () => clearInterval(interval);
              }, [targetValue]);
      
              return smoothedValue;
          };
      
          const PumpDischargePressure = useSmoothValue(data.PumpDischargePressure);
          const PumpFlowRate = useSmoothValue(data.PumpFlowRate);
          const EngineRPM = useSmoothValue(data.EngineRPM);
          const TransmissionCurrentGear = useSmoothValue(data.TransmissionCurrentGear);
          const Voltage = useSmoothValue(data.Voltage);
          const TransmissionLockup = useSmoothValue(data.TransmissionLockup);
          const TransmissionLUBTemperature = useSmoothValue(data.TransmissionLUBTemperature);
          const EnginePressure = useSmoothValue(data.EnginePressure);
          const EngineLoadFactor = useSmoothValue(data.EngineLoadFactor);
          const EngineTemperature = useSmoothValue(data.EngineTemperature);
          const PumpChargePressure = useSmoothValue(data.PumpChargePressure);
          const PumpSwitchPressure = useSmoothValue(data.PumpSwitchPressure);
          const PumpLPPressure = useSmoothValue(data.PumpLPPressure);
          const PumpLPTemperature = useSmoothValue(data.PumpLPTemperature);

    return (
        <>
        {hasData && (
        <div className={styles.screen}>

        <NavBarGeneric customStyles={stylesUser} linkHome={"/dashboard"} linkDash={"/graph"}/>
            <LatTitle title={fracsData.length > 0 ? fracsData[0].Data.Name : "ALL"} subtitle={"Dashboard"} />
            <div className={styles.lineH}/>
            <div className={styles.contentBody}>
            {fracsData.length > 0 && (
                <CardFrac
                    customStyles={stylesUser}
                    name={fracsData[0].Data.Name} 

                    pumpDischargePressure={Math.round(PumpDischargePressure)}
                    pumpFlowRate={PumpFlowRate < 1 ? "0" : PumpFlowRate.toFixed(2)} 
                    engineRPM={Math.round(EngineRPM)} 
                    transmissionCurrentGear={fracsData[0].Data.TransmissionCurrentGear} 
                    voltage={Voltage.toFixed(1)} 

                    EnginePressure={Math.round(EnginePressure)} 
                    EngineTemperature={Math.round(EngineTemperature)} 
                    engineLoadFactor={Math.round(EngineLoadFactor)} 

                    transmissionLockup={fracsData[0].Data.TransmissionLockup ? "ON" : "OFF"} 
                    TransmissionLUBTemperature={Math.round(TransmissionLUBTemperature)} 
                    
                    pumpSwitchPressure={Math.round(PumpSwitchPressure)} 
                    pumpChargePressure={Math.round(PumpChargePressure)}
                    PumpLPPressure={Math.round(PumpLPPressure)} 
                    PumpLPTemperature ={Math.round(PumpLPTemperature)} 
                />
            )}
            </div>

            <Footer />

            <LatTitle title="Geocalización" subtitle={"Visualización en tiempo real "} showButton={false} />
                    <div className={styles.mapContainer}>
                        <Map />
                    </div>
                    <Footer />
                    <div className={styles.margen} />
        </div>
        )}
        </>
    )
}

export default LatMain;