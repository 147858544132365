import React from 'react';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";

export function NotFound(){
    const navigate = useNavigate()

    useEffect(()=> {
        setTimeout(()=> {
            navigate(-1)
        }, 1500)
    }, []
    )
    return (
    <Layout>
        <h1>Not Found</h1>

    </Layout>)
}