export const dictionaryVariableNames = {
    EngineLoadFactor: "Factor de Carga del Motor (%)",
    EnginePressure: "Presión del Motor (PSI)",
    EngineRPM: "RPM del Motor",
    EngineTemperature: "Temperatura del Motor (°C)",
    PumpChargePressure: "Presión de Carga (PSI)",
    PumpDischargePressure: "Presión de Descarga (PSI)",
    PumpFlowRate: "Caudal (BPM)",
    PumpHPPressure: "Presión de HP (PSI)",
    PumpHPTemperature: "Temperatura de HP (°C)",
    PumpLPPressure: "Presión de Lubricación (PSI)",
    PumpLPTemperature: "Temperatura de Lubricación (°C)",
    PumpSwitchPressure: "TRIP (PSI)",
    TransmissionCurrentGear: "Marcha Actual",
    TransmissionLUBTemperature: "Temperatura de Lubricación (°C)",
    TransmissionLockup: "Bloqueo de Transmisión",
    Voltage: "Voltaje (V)",
};