export async function getLocationName(lat, lng) {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.address) {
            const { city, town, village, state, country } = data.address;
            return `${city || town || village || state}, ${country}`;
        }
        return "Ubicación desconocida";
    } catch (error) {
        console.error("Error obteniendo la ubicación:", error);
        return "Ubicación desconocida";
    }
}
