import React from "react";
import styles from './BlenderCard.module.css'
import MonitorValue from "../../MonitorValue";

const BlenderCard = (props) => {
    
    return (
        <div className={styles.container}>
            <h2>{props.title ?? 'Presión'}</h2>
            <div className={styles.dataContainer}>
                <MonitorValue value={props.value} unity={props.unity ?? 'BPM'}/>
            </div>
        </div>
    )
} 

export default BlenderCard;