import {initializeApp} from 'firebase/app';
import {getFirestore, collection, query, where, getDocs, Timestamp, documentId} from 'firebase/firestore';
import {get, getDatabase, onValue, ref} from 'firebase/database';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";
import { useAuth } from '../context/AuthContext';

export const firebaseConfig = {
    apiKey: "AIzaSyDgOJ_eLFyU24rfGBPixqVXFrNf0HUYPxM",
    authDomain: "javascript-27f38.firebaseapp.com",
    databaseURL: "https://javascript-27f38-default-rtdb.firebaseio.com",
    projectId: "javascript-27f38",
    storageBucket: "javascript-27f38.appspot.com",
    messagingSenderId: "444267039424",
    appId: "1:444267039424:web:f95e1011cd0ca3b1e2bb25"
};

export const server = initializeApp(firebaseConfig);

export const auth = getAuth();

export const createUser = createUserWithEmailAndPassword

export const loginWithEmailandPassword = signInWithEmailAndPassword

export const singOutApp = signOut

export const resetPasswordApp = sendPasswordResetEmail

export const observerAuth = onAuthStateChanged
// Obtiene una referencia a la base de datos de Firebase
export const database = getDatabase(server);

// Crea un escuchador en tiempo real para cambios en la ruta 'fracturador'
export const fracturadorRef = ref(database,'/Bahia');

export const BullBoxRef = ref(database,'/BullBox');

export const observer = onValue

export const getUserData = async (uid) => {
  const db = getDatabase();
  const userRef = ref(db, `BullBox/Users/${uid}`);
  const snapshot = await get(userRef);

  if (snapshot.exists()) {
    return snapshot.val(); // { email: "...", company: "...", role: "..." }
  } else {
    return null;
  }
}

export const Firestore = getFirestore(server);

export const GetEquipmentDataForDay = async (user, equipmentId, dateStart, dateEnd) => {
  try {


    const equipmentDataRef = collection(
      Firestore,
      `company/${user.company}/equipos/${equipmentId}/datos`
    );

    const dateStartString = new Date(dateStart)
    dateStartString.setHours(dateStartString.getHours() - 3)
    const dateStartParsed = dateStartString.toISOString().replace(".000", "");

    const dateEndString = new Date(dateEnd)
    dateEndString.setHours(dateEndString.getHours() - 3)
    const dateEndParsed = dateEndString.toISOString().replace(".000", "");

    // const dateStartString = new Date(dateStart).toISOString().replace(".000", ""); // Eliminar milisegundos
    // const dateEndString = new Date(dateEnd).toISOString().replace(".000", "");

 
     // Construir la consulta filtrando por documentId
    const q2 = query(
       equipmentDataRef,
       where(documentId(), ">=", dateStartParsed), // Desde la fecha de inicio
       where(documentId(), "<=", dateEndParsed)   // Hasta la fecha de fin
     );

    // Ejecutar la consulta
    const querySnapshot = await getDocs(q2);

    // Procesar los datos
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;

    
  } catch (error) {
    console.error("Error obteniendo datos del equipo: ", error);
    throw error;
  }
};