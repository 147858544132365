import React from 'react';
import PropTypes from 'prop-types';

const StatusIndicator = ({ status, text, backgroundColor, borderColor }) => {
  const circleStyle = {
    backgroundColor: status  ? backgroundColor : 'transparent',
    border: `2.8px solid ${status  ? borderColor : '#90A4AE'}`,
    borderRadius: '50%',
    width: '4.3vh',
    height: '4.3vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8vh',
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'center'
  };

  return (
    <div style={circleStyle}>
      {text}
    </div>
  );
};

StatusIndicator.propTypes = {
  status: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default StatusIndicator;
