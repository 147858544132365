import React from "react";
import styles from './Transmision.module.css'
import MonitorValue from "../MonitorValue";
import LatData from "../Data";

const Transmision = ({customStyles = {}, lockUp, Temp}) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: customStyles.backgroundCards}}>
            <div className={styles.containertitle}>  
                <h1 className={styles.title}>TRANSMISIÓN</h1>
                <div className={styles.rec}></div>
            </div>
            <div className={styles.containerchild}>
                <LatData title={"LockUp"} value={lockUp} unit={""}/>
                <LatData title={"Temperatura"} value={Temp} unit={"°C"}/>
            </div>
        </div>
    )
} 

export default Transmision;