import React from "react";
import styles from './Title.module.css'
import { isMobile } from "react-device-detect";

const Title = ({deposit, location, etapa, well, operator}) => {
    console.log(isMobile)
    return (
    <div className={styles.container}>
        <h1>FRACTURE SET</h1>
        
        {!isMobile && (
            <>
            <h6>DEPOSIT:</h6>
            <h5>{deposit ?? null}</h5>
            <h6>LOCATION:</h6>
            <h5>{location}</h5>
            <h6>ETAPA:</h6>
            <h5>{etapa}</h5>
            <h6>WELL:</h6>
            <h5>{well}</h5>
            <h6>OPERATOR:</h6>
            <h5>{operator}</h5>
            </>
        )}
    </div>
    )
}

export default Title