import React, {  useState } from "react";
import styles from './NavBarGeneric.module.css';
import { useAuth } from '../../context/AuthContext';
import { Link, useNavigate } from "react-router-dom";
import LogoLAT from '../../assets/LogoLAT.png';
import IcoUser from '../../assets/usuario.png';
import IcoHome from '../../assets/hogar.png';
import IcoDash from '../../assets/dash.png';
import Title from "../Title";
import LogoQM from '../../QM - blanco.png';
import { isMobile } from "react-device-detect";

const NavBarGeneric = ({customStyles = {}, linkHome, linkDash}) => {

    const {logout} = useAuth()
    const navigate = useNavigate()


  
    const HandleLogOut = async () => {
        try {
          await logout()
          navigate('/login')
        } catch (error) {
          alert('Failed to log out')
        }
      }
      const HandleHome = async () => {
        try {
          navigate('/home')
        } catch (error) {
          alert('Failed to redirect')
        }
      }

      
    return (

        <nav className={`${styles.navbar} ${isMobile ? styles.mobile : ""}`} style={{backgroundColor:customStyles.backgroundNavBar}}>
          <img src={customStyles.LogoNavBar} alt="" className={styles.image} 
            style={{height: customStyles.LogoNavBarHeight, marginBottom:customStyles.LogoNavBarMarginBottom}} />

          <div className={`${styles.container} ${isMobile ? styles.mobile : ""}`}>
            
            <Link to={linkHome} className={styles.logout}>
              <div className={styles.user}>
                <img src={IcoHome}  className={styles.userico} />
              </div>            
            </Link>

            <Link 
                to="/graph" 
                state={{ customStyles }} 
                className={styles.logout}
              >
              <div className={styles.user}>
                <img src={IcoDash}  className={styles.userico} />
              </div>            
            </Link>

            <Link onClick={() => HandleLogOut()} className={styles.logout}>
              <div className={styles.user}>
                <img src={IcoUser} alt="" className={styles.userico} />
              </div>            
            </Link>
            
          </div>
        </nav>
        


    )
}

export default NavBarGeneric