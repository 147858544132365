import React from "react";
import styles from './Motor.module.css'
import MonitorValue from "../MonitorValue";
import LatData from "../Data"; 

const Motor = ({customStyles = {}, presion, temp, factorCarga}) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: customStyles.backgroundCards}}>
            <div className={styles.containertitle}>
                <h1>MOTOR</h1>
                <div className={styles.rec}></div> 
            </div>
               
            <div className={styles.containerchild}>
                <LatData title={"Presión"} value={presion} unit={"PSI"}/>
                <LatData title={"Temperatura"} value={temp} unit={"°C"}/>
                <LatData title={"Factor de carga"} value={factorCarga} unit={"%"}/>
            </div>
        </div>
    )
} 

export default Motor;