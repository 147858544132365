import React from "react";
import styles from './LatCard.module.css'
import MonitorValue from "../LatMonitorValue";

const LatCard = ({title, value, unit, background}) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: background}}>
            <h2> {title}</h2>
            <div className={styles.dataContainer} >
                <MonitorValue value={value} unity= {unit}/>
            </div>
        </div>
    )
} 

export default LatCard;