import React from "react";
import styles from './LatCardTransmision.module.css'
import MonitorValue from "../LatMonitorValue";
import LatData from "../LatData";

const LatCardTransmision = (props) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: props.background}}>
            <h1>TRANSMISIÓN</h1>
            <div className={styles.rec}></div>
            <div className={styles.containerchild}>
                <LatData title={"LockUp"} value={props.TransmissionLockup ? "ON" : "OFF"} unit={""}/>
                <LatData title={"Temperatura"} value={props.TransmissionLUBTemperature} unit={"°C"}/>
            </div>
        </div>
    )
} 

export default LatCardTransmision;