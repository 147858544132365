import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import 'chartjs-adapter-moment'; 
import{ isMobile } from 'react-device-detect';
import "../DashboardGraf.module.css"
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Colors,
} from 'chart.js';
import 'chartjs-adapter-moment';

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const dictionary = {
    EngineLoadFactor: "Factor de Carga del Motor",
    EnginePressure: "Presión del Motor",
    EngineRPM: "RPM del Motor",
    EngineTemperature: "Temperatura del Motor",
    PumpChargePressure: "Presión de Carga",
    PumpDischargePressure: "Presión de Descarga (PSI)",
    PumpFlowRate: "Caudal (BPM)",
    PumpHPPressure: "Presión de HP",
    PumpHPTemperature: "Temperatura de HP",
    PumpLPPressure: "Presión de Lubricación",
    PumpLPTemperature: "Temperatura de Lubricación",
    PumpSwitchPressure: "TRIP",
    TransmissionCurrentGear: "Marcha Actual",
    TransmissionLUBTemperature: "Temperatura de Lubricación",
    TransmissionLockup: "Bloqueo de Transmisión",
    Voltage: "Voltaje",
};
const Chart = ({data,var1,var2}) => {
    const [chartData, setChartData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    

    useEffect(() => {
        try {
            
            const parsedData = data.map((row) => ({
                // timestamp: moment(`${row.Fecha} ${row.Hora}`, "YYYY/MM/DD H:m:s:SSS").valueOf(),
                timestamp: moment(row.id, "YYYY-MM-DDTHH:mm:ssZ").valueOf(),
                minute: moment(row.id, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm"), // Clave para filtrar por minuto
                Presion:  parseFloat(row.data[var1]) || 0,
                Caudal:  parseFloat(row.data[var2]) || 0,

                
            }));

            // Aplicar filtro de un dato por minuto
            const uniqueMinutes = new Set();
            const filtered = parsedData.filter(row => {
                if (!uniqueMinutes.has(row.minute)) {
                    uniqueMinutes.add(row.minute);
                    return true;
                }
                return false;
            });
            setFilteredData(filtered);
            updateChart(filtered);  // Ahora con el filtro aplicado
        } catch (error) {
            console.log("Error al procesar los datos", error);
        }
    }, [data]);

   
         const updateChart = (data) => {
             const presionData = data.map((row) => ({
                 x: row.timestamp,
                 y: row.Presion,
             }));
     
             const caudalData = data.map((row) => ({
                 x: row.timestamp,
                 y: row.Caudal,
             }));
     
             setChartData({
                 datasets: [
                     {
                         label: dictionary[var1],
                         data: presionData,
                         borderColor: "#a36769",
                         backgroundColor: "rgba(163, 103, 105, 0.3)",
                         tension: 0.4,
                         pointRadius: 4,
                         borderWidth: 1,
                         showLine: true,
                         yAxisID: "y1",
                     },
                     {
                         label: dictionary[var2],
                         data: caudalData,
                         borderColor: "#367aa3",
                         backgroundColor: "rgba(54, 122, 163, 0.3)",
                         tension: 0.4,
                         pointRadius: 4,
                         borderWidth: 1,
                         showLine: true,
                         yAxisID: "y2",
                     },
                 ],
             });
         };

    return (
        <>  
           
            {chartData ? (
                <Line
                    data={chartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: isMobile ? false : true ,
                        plugins: {
                            legend: { position: "top" },
                            title: { display: true, text: "Gráfico de Presión y Caudal vs Fecha" },
                        },
                        scales: {
                            x: {
                                type: "time",
                                time: { unit: "minute", tooltipFormat: "YYYY-MM-DD HH:mm:ss" },
                                title: { display: true, text: "Fecha y Hora" },
                                grid: {
                                    drawOnChartArea: false, 
                                    
                                }
                            },
                            y1: { type: "linear", position: "left", title: { display: true, text: dictionary[var1] }, },
                            y2: { type: "linear", position: "right", title: { display: true, text: dictionary[var2] }, grid: { drawOnChartArea: false } },
                        },
                    }}
                />
            ) : (
                <p>Cargando datos...</p>
            )}
        </>
    );
};

export default Chart;