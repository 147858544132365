import React from 'react'
import styles from './style.module.css'
import Header from '../Header'
import Footer from '../Footer'


 const Layout = ({children}) => {
  return (
    <body className={styles.bodyf}>
       
          <Header />

        <main className={styles.mainContent}>
          {children}
        </main>
        
          <Footer />
       
    </body>
  )
}

export default Layout