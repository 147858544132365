import React from "react";
import styles from './CardFrac.module.css'
import './CardFrac.module.css'
import StatusIndicator from "../../StatusIndicator";
import { useNavigate } from "react-router-dom";

import LogoTenaris from '../../../assets/LogoTenaris.png';
import fotoTenaris from '../../../assets/FracTenaris.png';

import { isMobile } from 'react-device-detect';
import Foto from "../../Latitud/LatMain/CardFrac/Foto/Foto";

const CardFrac = (props) => {
  const navigate = useNavigate()

  const stylesTenaris = {
    backgroundNavBar: '#32413F',
    LogoNavBar: LogoTenaris,
    LogoNavBarHeight: "2.5vh",
    LogoNavBarMarginBottom: "",
    backgroundCards:"rgb(247, 248, 250)",
    Foto: fotoTenaris,
    FotoHeight : "18.4vh",
    FotoWidthMobile : "90%",
    FotoMargin_right: "4vw",
    FotoMargin_top: "1vh",
    FotoContainerWidth: "15vw",
}

  const handleClick = () => {
    console.log("Enviando a dashboard:", props.name, "con estilos:", stylesTenaris);
    navigate(isMobile ? `/dashboard/m/${props.name}` : `/dashboard/${props.name}`, { state: { customStyles: stylesTenaris } });
  };
    return (
        <div key={props.name} className={styles.container} 
        onClick={handleClick} // Evento de click
        style={{ cursor: "pointer" }} // Cursor de puntero para indicar que es interactivo
        >
            <h3>{props.name}</h3>
            <h4>RPM</h4>
            <p className={styles.tituloValue}>Deseada</p>
            <p className={styles.desired}>{props.engineRPM}</p>
            <p className={styles.tituloValue}>Actual</p>
            <p className={styles.current}>{props.engineRPM}</p>
            <div className={styles.divition}/>
            <h4>MARCHA</h4>
            <p className={styles.tituloValue}>Deseada</p>
            <p className={styles.desired}>{props.transmissionCurrentGear}</p>
            <p className={styles.tituloValue}>Actual</p>
            <p className={styles.current}>{props.transmissionCurrentGear}</p>
            <div className={styles.divition}/>
            <h4 style={{paddingBottom: '0vh'}}>Presión</h4>
            <p className={styles.currentP}>{props.pumpDischargePressure}</p>
            <p className={styles.tituloValue}>TRIP</p>
            <p className={styles.desiredP}>{props.pumpSwitchPressure}</p>
            <div className={styles.divition}/>
            <div className={styles.table}>
              <div className={styles.table2}>
                <th>Caudal</th>
                <td>{props.pumpFlowRate}</td>
              </div>
              <div className={styles.table2}>
                <th>% Carga</th>
                <td>{props.engineLoadFactor}</td>
              </div>
            </div>
            <div className={styles.divition} style={{marginTop: '1vh'}}/>
            <div className={styles.table}>
              <div className={styles.table2}>
                  <th>Succión</th>
                  <td>{props.pumpChargePressure}</td>
                </div>
                <div className={styles.table2}>
                  <th>Tensión</th>
                  <td>{props.voltage}</td>
              </div>
            </div>
            
           <div className={styles.indicators}>
              <StatusIndicator key='Start' status={props.engineRPM > 500 } text='Engine Start' backgroundColor="#A7FFEB" borderColor="#00A84F"/>
              <StatusIndicator key='Lockup' status={props.transmissionLockup} text="Lock-Up" backgroundColor="#FF8A80" borderColor="#D32F2F"/>

           </div>
          </div>
    )
}

export default CardFrac;