import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../../../context/AuthContext"
import styles from "../style.module.css"
import Layout from "../../Layout"
import { isMobile } from "react-device-detect" 


export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, currentUser } = useAuth(); // Obtener el usuario actual
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  async function handleSubmit(e) {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await login(emailRef.current.value, passwordRef.current.value);
    } catch (error) {
      setError("Failed to log in");
      console.log(error);
    }

    setLoading(false);
  }

  // Observa cambios en el usuario autenticado y redirige si está autenticado
  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    }
  }, [currentUser, navigate]);


  return (
    <Layout>

      <div className={`${styles.container} ${isMobile ? styles.mobile : ""}`}>
        <div className={styles.card}>  
            <h2 className={styles.title}>Log In</h2>
            {error && <span className={styles.error}>{error}</span>}
            <form onSubmit={handleSubmit}>
              <div id="email" className={styles.field}>
                <label>Email</label>
                <input className={`${styles.inp} ${isMobile ? styles.mobile : ""}`} type="email" ref={emailRef} required />
              </div>
              <div id="password" className={styles.field}>
                <label>Password</label>
                <input className={`${styles.inp} ${isMobile ? styles.mobile : ""}`} type="password" ref={passwordRef} required />
              </div>
              
              <Link to="/forgot-password" className={styles.link}>Forgot Password?</Link>
            
              <div className={styles.forgotPassword}>
                <button className={`${styles.btn} ${isMobile ? styles.mobile : ""}`} disabled={loading} type="submit">
                  Log In
                </button>
              </div>
            </form>
        </div>
        <div className={styles.signup}>
          Need an account? <Link to="/signup" className={styles.link}>Sign Up</Link>
        </div>
      </div>
    </Layout>
  )
}