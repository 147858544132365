import React, { useState } from "react";
import styles from "./TimeFilter.module.css"
import { isMobile } from "react-device-detect";

const TimeFilter = ({ startDate, endDate, startTime, endTime, setStartDate, setEndDate, setStartTime, setEndTime }) => {
    
    

    return (
        <div className={`${styles.filtersContent} ${isMobile ? styles.mobile : ""}`}>

            <div className={`${styles.filtersFiler} ${isMobile ? styles.mobile : ""}`}>
                <label >
                    Desde Fecha:
                    <input 
                        type="date" 
                        value={startDate} 
                        onChange={(e) => setStartDate(e.target.value) } 
                        
                    />
                </label>
                <label >
                    Hasta Fecha:
                    <input 
                        type="date" 
                        value={endDate} 
                        onChange={(e) => setEndDate(e.target.value) } 
                        
                    />
                </label>
            </div>
            
            <div className={`${styles.filtersFiler} ${isMobile ? styles.mobile : ""}`}>
            <label>
                Desde Hora:
                <input 
                    type="time" 
                    value={startTime} 
                    onChange={(e) => setStartTime(e.target.value) } 
                    
                />
            </label>
            <label>
                Hasta Hora:
                <input 
                    type="time" 
                    value={endTime} 
                    onChange={(e) => setEndTime(e.target.value) } 
                    
                />
            </label>
            </div>
            
        </div>

    );
};

export default TimeFilter;