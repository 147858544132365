import React, { useEffect, useState } from 'react';
import { database,  observer } from '../../../server/firebase'
import Footer from '../../Footer';
import NavBarGeneric from '../../NavBarGeneric';
import styles from './LatOne.module.css'
import CardFrac from '../../Tenaris/CardFrac';
import LatTitle from '../LatTitle';
import LatCard from '../LatCard';
import CardFoto from '../LatCardFoto';
import CardTransmision from '../LatCardTransmision';
import CardFotoMotor from '../LatCardMotor';
import CardBomba from '../LatCardBomba';
import { useAuth } from '../../../context/AuthContext';
import { ref } from 'firebase/database';
import { useParams } from 'react-router-dom';
import Map from '../LatOne/Map';
import buttongps from '../../../assets/butongps.png';
import { getLocationName } from '../../locationService';
import { useLocation } from "react-router-dom";

const LatOne = ({ customStyles = {} }) => {
    const [fracsData, setFracsData] = useState([]);
    const { currentUser } = useAuth();
    const [location, setLocation] = useState("Cargando...");

    const Location = useLocation();
    const stylesUser = Location.state?.customStyles || {}; // Asegura que no sea undefined
    console.log("Estilos recibidos en LatMain:", stylesUser.LogoNavBarHeight);

    useEffect(() => {
        const companyRef = ref(database, `/BullBox/Companies/${currentUser?.company}`);
        observer(companyRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const fracs = Object.values(data);
                setFracsData(fracs);
                console.log(fracs);  // Asegúrate de que los datos son correctos
            }
        });
    }, [currentUser]);

    useEffect(() => {
        async function fetchLocation() {
            const name = await getLocationName(-38.002714, -57.668606);
            setLocation(name);
        }
        fetchLocation();
    }, []);

    // Verifica que `fracsData` no esté vacío y que `fracsData[0].Data` exista antes de acceder
    const hasData = fracsData.length > 0 && fracsData[0]?.Data;

    // Si no hay datos aún, usa valores por defecto (evita el error de undefined)
    const defaultData = {
        PumpDischargePressure: 0,
        PumpFlowRate: 0,
        EngineRPM: 0,
        TransmissionCurrentGear: 0,
        Voltage: 0,
        TransmissionLockup: 0,
        TransmissionLUBTemperature: 0,
        EnginePressure: 0,
        EngineLoadFactor: 0,
        EngineTemperature: 0,
        PumpChargePressure: 0,
        PumpSwitchPressure: 0,
        PumpLPPressure: 0,
        PumpLPTemperature: 0,
        Name: "Sin datos"
    };

    const data = hasData ? fracsData[0].Data : defaultData;

    const useSmoothValue = (targetValue, smoothingFactor = 0.75) => {
        const [smoothedValue, setSmoothedValue] = useState(targetValue);

        useEffect(() => {
            const interval = setInterval(() => {
                setSmoothedValue((prev) => prev + (targetValue - prev) * smoothingFactor);
            }, 100);

            return () => clearInterval(interval);
        }, [targetValue]);

        return smoothedValue;
    };

    const PumpDischargePressure = useSmoothValue(data.PumpDischargePressure);
    const PumpFlowRate = useSmoothValue(data.PumpFlowRate);
    const EngineRPM = useSmoothValue(data.EngineRPM);
    const TransmissionCurrentGear = useSmoothValue(data.TransmissionCurrentGear);
    const Voltage = useSmoothValue(data.Voltage);
    const TransmissionLockup = useSmoothValue(data.TransmissionLockup);
    const TransmissionLUBTemperature = useSmoothValue(data.TransmissionLUBTemperature);
    const EnginePressure = useSmoothValue(data.EnginePressure);
    const EngineLoadFactor = useSmoothValue(data.EngineLoadFactor);
    const EngineTemperature = useSmoothValue(data.EngineTemperature);
    const PumpChargePressure = useSmoothValue(data.PumpChargePressure);
    const PumpSwitchPressure = useSmoothValue(data.PumpSwitchPressure);
    const PumpLPPressure = useSmoothValue(data.PumpLPPressure);
    const PumpLPTemperature = useSmoothValue(data.PumpLPTemperature);

    return (
        <>
            {hasData && (
                <div className={styles.screen}>
                    <NavBarGeneric customStyles={stylesUser} linkHome={"/dashboard"} linkDash={"/graph"} />
                    <LatTitle title={data.Name} subtitle={"DASHBOARD"} location={location} />
                    <div className={styles.cardsContainer}>
                        <LatCard title={"PRESION DESCARGA"} value={Math.round(PumpDischargePressure)} 
                            unit={"PSI"} background={"#f7f8fa"} />
                        <LatCard title={"CAUDAL"} value={PumpFlowRate < 1 ? "0" : PumpFlowRate.toFixed(2)} 
                            unit={"BPM"} background={"#f7f8fa"} />
                        <LatCard title={"RPM"} value={Math.round(EngineRPM)} 
                            unit={""} background={"#f7f8fa"} />
                        <LatCard title={"MARCHA"} value={fracsData[0].Data.TransmissionCurrentGear} 
                            unit={""} background={"#f7f8fa"} />
                        <LatCard title={"VOLTAJE"} value={Voltage.toFixed(1)} 
                            unit={"V"} background={"#f7f8fa"} />
                    </div>
                    <div className={styles.lineH} />
                    <div className={styles.contentBody}>
                        <div className={styles.contentchildIzq}>
                            <div className={styles.contentchilTop}>
                                <CardFoto customStyles={stylesUser} />
                                <CardTransmision
                                    TransmissionLockup={TransmissionLockup}
                                    TransmissionLUBTemperature={TransmissionLUBTemperature.toFixed(1)}
                                    background={"#f7f8fa"}
                                />
                            </div>
                            <CardFotoMotor
                                EnginePressure={Math.round(EnginePressure)}
                                EngineLoadFactor={Math.round(EngineLoadFactor)}
                                EngineTemperature={Math.round(EngineTemperature)}
                                background={"#f7f8fa"}
                            />
                        </div>
                        <div className={styles.lineV} />
                        <CardBomba
                            PumpChargePressure={Math.round(PumpChargePressure)}
                            PumpSwitchPressure={Math.round(PumpSwitchPressure)}
                            PumpLPPressure={Math.round(PumpLPPressure)}
                            PumpLPTemperature={Math.round(PumpLPTemperature)}
                            background={"#f7f8fa"}
                        />
                    </div>

                    <Footer />
                    <LatTitle title="Sistema de localización" subtitle={"Visualización en tiempo real de la ubicación actual"} showButton={false} />
                    <div className={styles.mapContainer}>
                        <Map />
                    </div>
                    <Footer />
                    <div className={styles.margen} />
                </div>
            )}
        </>
    );
};

export default LatOne;