import React from "react";
import styles from './LatCardMotor.module.css'
import MonitorValue from "../LatMonitorValue";
import LatData from "../LatData"; 

const LatCardMotor = (props) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: props.background}}>
                <h1>MOTOR</h1>
                <div className={styles.rec}></div>        
            <div className={styles.containerchild}>
                <LatData title={"Presión"} value={props.EnginePressure} unit={"PSI"}/>
                <LatData title={"Temperatura"} value={props.EngineTemperature} unit={"°C"}/>
                <LatData title={"Factor de carga"} value={props.EngineLoadFactor} unit={"%"}/>
            </div>
        </div>
    )
} 

export default LatCardMotor;