import React from "react";
import styles from './Insight.module.css'

const Insight = ({customStyles = {}, title, value, unit}) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: customStyles.backgroundCards}}>
            <h2>{title}</h2>
            <div className={styles.containerchild}>
                <p className={styles.value}>{value}</p>
                <p className={styles.unity}>{unit}</p>
            </div>
            

        </div>
    )
} 

export default Insight;