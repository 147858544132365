import React, { useEffect, useState } from 'react';
import { database,  observer } from '../../../../server/firebase'
import { useAuth } from '../../../../context/AuthContext';
import { ref } from 'firebase/database';
import styles from './CardFrac.module.css'
import Insight from './Insight/Insight';   
import Motor from './Motor/Motor'; 
import Transmision from './Transmision/Transmision';
import Bomba from './Bomba/Bomba'; 
import Foto from './Foto/Foto';
import { isMobile } from "react-device-detect";

const CardFrac = ({ customStyles = {},
                    name,
                    transmissionCurrentGear, pumpDischargePressure,
                    pumpSwitchPressure, pumpFlowRate, engineLoadFactor, pumpChargePressure,
                    voltage, transmissionLockup, engineRPM, EnginePressure, EngineTemperature, TransmissionLUBTemperature,
                    PumpLPTemperature,PumpLPPressure
  }) => {
    

    return (
        <>
        <div className={`${styles.body} ${isMobile ? styles.mobile : ""}`}>
            <div className={`${styles.contain1} ${isMobile ? styles.mobile : ""}`}>
                <Insight customStyles={customStyles} title={"PRESION DESCARGA"} value={pumpDischargePressure} unit={"PSI"}/>
                <Insight customStyles={customStyles} title={"CAUDAL"} value={pumpFlowRate} unit={"BPM"}/>
                <Insight customStyles={customStyles} title={"RPM"} value={engineRPM} unit={""}/>
            </div>
            <div className={`${styles.contain1} ${isMobile ? styles.mobile : ""}`}>
                <Foto customStyles={customStyles} linkFrac={`/dashboard/${name}`} name={name}/>
                <Insight customStyles={customStyles} title={"MARCHA"} value={transmissionCurrentGear} unit={""}/>
                <Insight customStyles={customStyles} title={"VOLTAJE"} value={voltage} unit={"V"}/>
            </div>
            
            <div className={`${styles.contain2} ${isMobile ? styles.mobile : ""}`}>
                <Motor customStyles={customStyles} presion={EnginePressure} temp={EngineTemperature} factorCarga={engineLoadFactor}/>
                <Transmision customStyles={customStyles} lockUp={transmissionLockup} Temp={TransmissionLUBTemperature}/>
            </div>
            <div className={styles.margen}></div>
            <Bomba customStyles={customStyles} presionSuc={pumpChargePressure} trip={pumpSwitchPressure} presionLub={PumpLPPressure} tempLub={PumpLPTemperature} />
        </div>
        </>
    )
}

export default CardFrac;