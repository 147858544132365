import React from "react"
import {  Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"

export default function PrivateRoutes({ component: Component, ...rest })  {
  const { currentUser } = useAuth()
  return (
    currentUser ? <Outlet
      {...rest}
      // render={props => {
      //   return currentUser ? <Component {...props} /> : <Navigate to="/login" />
      // }}
    /> : <Navigate to="/login" />
   
    
  )
}