import React from "react";
import styles from './LatTitle.module.css'
import { isMobile } from "react-device-detect";
import buttongps from '../../../assets/butongps.png';
import contenedorScrollPC from "../LatOne/LatOne.module.css";
import contenedorScrollMobile from "../LatMain/LatMain.module.css";


const LatTitle = ({title, subtitle, location, showButton=true}) => {

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const fechaFormateada = new Date().toLocaleDateString('es-ES', options);
    return (
        <div className={`${styles.title} ${isMobile ? styles.mobile : ""}`}>
            <div className={styles.container}>
                <div className={styles.containerchild}>
                    <h1>{title}</h1>
                    <h1 style={{margin:"0px 2vw"}}>/</h1>
                    <h2>{subtitle}</h2>
                </div>

                {showButton && (
                    <button className={styles.buttonMap} 
                        onClick={() =>{
                            console.log("Botón presionado");
                            const contenedorScroll = isMobile ? contenedorScrollMobile : contenedorScrollPC;
                            const scrollContainer = document.querySelector(`.${contenedorScroll.screen}`);
                            console.log("contenedorScroll:", contenedorScroll);

                                if (scrollContainer) {
                                    console.log("entro");
                                    console.log("scrollHeight:", scrollContainer.scrollHeight);
                                    console.log("clientHeight:", scrollContainer.clientHeight);
                                    scrollContainer.scrollTo({ top: scrollContainer.scrollHeight, behavior: "smooth" });
                                    } else {
                                    console.error("No se encontró el contenedor con la clase .LatOne_screen");
                                    }
                            
                        }  
                    }>
                    
                    <h3 className={`${isMobile ? styles.fecha : ""}`}> {location} </h3>
                    <img className={styles.ico} src={buttongps}/>
                </button>
                )}
                
            </div>
        </div>
    )
}

export default LatTitle