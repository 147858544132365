import React from "react";
import styles from './Bomba.module.css'
import LatData from "../Data";

const Bomba = ({customStyles = {}, presionSuc, trip, presionLub, tempLub}) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: customStyles.backgroundCards}}>
            <div className={styles.containertitle}>
                <h1 className={styles.title}>BOMBA</h1>
                <div className={styles.rec}></div>
            </div>
            
            <div className={styles.containerchild}>
                <LatData title={"Presión succion"} value={presionSuc} unit={"PSI"}/>
                <LatData title={"Trip"} value={trip} unit={"PSI"}/>
                </div>
            <div className={styles.containerchild}>
                <LatData title={"Presión LUB"} value={presionLub} unit={"PSI"}/>
                <LatData title={"Temp LUB"} value={tempLub} unit={"°C"}/>
            </div>
            
        </div>
    )
} 

export default Bomba;