import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import styles from "../FilterPanel/FilterPanel.module.css";
import { isMobile } from "react-device-detect";

const DataExport = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No hay datos para exportar</p>;
  }

  // Extraer claves para usarlas como encabezados
  const headers = Object.keys(data[0].data);

  // 🔹 Función para exportar a Excel
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Datos");

    // Agregar encabezados
    sheet.addRow(headers);

    // Agregar datos
    data.forEach((row) => {
      sheet.addRow(headers.map((header) => row.data[header]));
    });

    // Generar el archivo y descargar
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "datos.xlsx");
  };

  // 🔹 Función para exportar a CSV
  const exportToCSV = () => {
    const csv = Papa.unparse({
      fields: headers,
      data: data.map((row) => headers.map((header) => row.data[header])),
    });

    // Crear archivo y descargar
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "datos.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div class name={styles.containerBtn}>
      <button className={`${styles.btn} ${isMobile ? styles.mobile : ""}`} onClick={exportToExcel}>Descargar Excel</button>
      <button className={`${styles.btn} ${isMobile ? styles.mobile : ""}`} onClick={exportToCSV}>Descargar CSV</button>
    </div>
  );
};

export default DataExport;
