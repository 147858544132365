import React from "react";
import styles from './Footer.module.css'
import qm from '../../assets/QM_footer.png'
const Footer = () => {
    return (
        <div className={styles.container}>
            <p className={styles.poweredBy}>Powered by</p>
            <img src={qm} className={styles.image}/>
        </div>
    )
}

export default Footer