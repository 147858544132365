import React, {  useState } from "react";
import { useAuth } from '../../context/AuthContext';
import { Link, useNavigate } from "react-router-dom";
import LogoTenaris from '../../assets/LogoTenaris.png'
import styles from './NavBar.css'
import IcoUser from '../../assets/usuario.png'

const NavBar = (props) => {
    const [activeTab, setActiveTab] = useState("fracturaLimpia"); // Inicializa con la pestaña deseada
    const {logout} = useAuth()
    const navigate = useNavigate()

    const handleTabClick = (tab) => {
      setActiveTab(tab);
      props.changeSet();
    };
    const HandleLogOut = async () => {
        try {
          await logout()
          navigate('/login')
        } catch (error) {
          alert('Failed to log out')
        }
      }
    return (
        <nav className='navbar'>
            <img src={LogoTenaris} style={{height:'2vh'}}/>
            <ul>
                <li className={activeTab === "fracturaLimpia" ? "active" : ""}>
                    <a className='navbarselect' onClick={() => handleTabClick("fracturaLimpia")}>CLEAN</a>
                </li>
                <li className={activeTab === "fracturaSucia" ? "active" : ""}>
                    <a className='navbarselect' onClick={() => handleTabClick("fracturaSucia")}>DIRTY</a>
                </li>
            </ul>
            <Link onClick={() => HandleLogOut()} className='logout'>
              <div className='user'>
                <img src={IcoUser} alt="" className='userico '/>
              </div>            
            </Link>
        </nav>
    )
}

export default NavBar