import React, { useState, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import Papa from 'papaparse';
import moment from 'moment';
import 'chartjs-adapter-moment'; 
import  './DashboardGraf.module.css'; 
import Filters from './Filters';
import LatCardGraf from '../LatCardGraf';

import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Colors,
} from 'chart.js';
import 'chartjs-adapter-moment';

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Tooltip, Legend);


const ScatterChart = () => {
    const [chartData, setChartData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [startDate, setStartDate] = useState("2024-11-01");
    const [endDate, setEndDate] = useState("2025-01-10");
    const [startTime, setStartTime] = useState("00:00");
    const [endTime, setEndTime] = useState("23:59");

    useEffect(() => {
        Papa.parse("../FracturaLAT.txt", {
            download: true,
            header: true,
            delimiter: ",",
            skipEmptyLines: true,
            complete: (result) => {
                const data = result.data.map((row) => ({
                    timestamp: moment(`${row.Fecha} ${row.Hora}`, "YYYY/MM/DD H:m:s:SSS").valueOf(),
                    minute: moment(`${row.Fecha} ${row.Hora}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm"), // Clave para filtrar por minuto
                    Presion: parseFloat(row.Presion) || 0,
                    Caudal: parseFloat(row.Caudal) || 0,
                    Fecha: row.Fecha,
                    Hora: row.Hora
                }));
    
                // Aplicar filtro de un dato por minuto
                const uniqueMinutes = new Set();
                const filtered = data.filter(row => {
                    if (!uniqueMinutes.has(row.minute)) {
                        uniqueMinutes.add(row.minute);
                        return true;
                    }
                    return false;
                });
    
                setFilteredData(filtered);
                updateChart(filtered);  // Ahora con el filtro aplicado
            },
        });
    }, []);

    // Filtrar los datos por rango de fecha y hora, y cada minuto
    const handleFilterChange = (startDate, endDate, startTime, endTime) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setStartTime(startTime);
        setEndTime(endTime);

        let filtered = filteredData.filter((row) => {
            const rowDate = moment(row.timestamp).format("YYYY-MM-DD");
            const rowTime = moment(row.timestamp).format("HH:mm");

            const isDateInRange = 
                (!startDate || rowDate >= startDate) &&
                (!endDate || rowDate <= endDate);

            const isTimeInRange = 
                (!startTime || rowTime >= startTime) &&
                (!endTime || rowTime <= endTime);

            return isDateInRange && isTimeInRange;
        });

        // Aplicar filtro de un dato por minuto
        const uniqueMinutes = new Set();
        filtered = filtered.filter(row => {
            if (!uniqueMinutes.has(row.minute)) {
                uniqueMinutes.add(row.minute);
                return true;
            }
            return false;
        });

        updateChart(filtered);
    };

    const updateChart = (data) => {
        const presionData = data.map((row) => ({
            x: row.timestamp,
            y: row.Presion,
        }));

        const caudalData = data.map((row) => ({
            x: row.timestamp,
            y: row.Caudal,
        }));

        setChartData({
            datasets: [
                {
                    label: "Presión",
                    data: presionData,
                    borderColor: "#a36769",
                    backgroundColor: "rgba(163, 103, 105, 0.3)",
                    tension: 0.4,
                    pointRadius: 4,
                    borderWidth: 1,
                    showLine: true,
                    yAxisID: "y1",
                },
                {
                    label: "Caudal",
                    data: caudalData,
                    borderColor: "#367aa3",
                    backgroundColor: "rgba(54, 122, 163, 0.3)",
                    tension: 0.4,
                    pointRadius: 4,
                    borderWidth: 1,
                    showLine: true,
                    yAxisID: "y2",
                },
            ],
        });
    };

    return (
        <>  
            <Filters onFilterChange={handleFilterChange} />
            {chartData ? (
                <Line
                    data={chartData}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: { position: "top" },
                            title: { display: true, text: "Gráfico de Presión y Caudal vs Fecha" },
                        },
                        scales: {
                            x: {
                                type: "time",
                                time: { unit: "minute", tooltipFormat: "YYYY-MM-DD HH:mm:ss" },
                                title: { display: true, text: "Fecha y Hora" },
                                grid: {
                                    drawOnChartArea: false, 
                                    
                                }
                            },
                            y1: { type: "linear", position: "left", title: { display: true, text: "Presión (PSI)" }, },
                            y2: { type: "linear", position: "right", title: { display: true, text: "Caudal (BPM)" }, grid: { drawOnChartArea: false } },
                        },
                    }}
                />
            ) : (
                <p>Cargando datos...</p>
            )}
        </>
    );
};

export default ScatterChart;