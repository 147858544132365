import React from "react"
import Signup from "./components/Auth/SingUp/index.jsx"
import {  Route, Routes, useNavigate } from "react-router-dom"
import Login from "./components/Auth/Login/index.jsx"
import ForgotPassword from "./components/Auth/ForgotPassword/index.jsx"
import PrivateRoutes from "./components/Auth/PrivateRoutes.jsx"
import { NotFound } from "./components/NotFound/NotFound.jsx"
import Home from "./components/Home/index.jsx"
import Dashboard from "./components/Dashboard/index.jsx"
import SetDesktop from "./components/Tenaris/DashboardTenaris/index.jsx"
import SetMobile from "./pages/SETMobile/index.jsx"
import SingleView from "./components/SingleView/index.jsx"
import Graph from "./components/Graph/index.jsx"
import LatMain from "./components/Latitud/LatMain/index.jsx"
import LatOne from "./components/Latitud/LatOne/index.jsx"



function App() {


  return (
    <>    
      <Routes>
        <Route element={<PrivateRoutes />}>
        <Route exact path="/dashboard" element={<Dashboard />}/>
          <Route exact path="/dashboard/Set" element={<SetDesktop />}/>
          <Route exac path="/dashboard/m/Set/" element={<SetMobile/>}/>
          <Route exact path="/graph" element={<Graph />} />
          <Route exact path="/dashboard/:FracName" element={<LatOne  />} />
          <Route exact path="/dashboard/QM" element={<LatOne  />} />
          <Route exact path="/dashboard/m/:FracName" element={<LatMain />} />
          <Route exact path="/dashboard/m/QM" element={<LatMain />} />
        </Route>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/home" element={<Home />}/>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  ) 
}

export default App