import React from "react";
import styles from './LatCardFoto.module.css'
// import LocalImage from '../src/assets/fracLAT.png';
import LocalImage from '../../../assets/Prometheus.png';
import MonitorValue from "../LatMonitorValue";

const LatCardFoto = ({customStyles}) => {
    
    return (
        <div className={styles.container} style={{backgroundColor: customStyles.backgroundCards,
            width: customStyles.FotoContainerWidth
        }}>
            <img src={customStyles.Foto} className={styles.image} style={{ height: customStyles.FotoHeight, 
                marginRight: customStyles.FotoMargin_right, marginTop: customStyles.FotoMargin_top
            }} />
        </div>
    )
} 

export default LatCardFoto;