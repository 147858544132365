import React, { useContext, useState, useEffect } from "react"
import { auth, createUser, getUserData, loginWithEmailandPassword, observerAuth, resetPasswordApp, singOutApp } from "../server/firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  async function signup(email, password) {
    return createUser(auth, email, password)
    // return auth.create
  }

  async function login(email, password) {
    return loginWithEmailandPassword(auth, email, password)
  }

  function logout() {
    return singOutApp(auth)
  }

  function resetPassword(email) {
    return resetPasswordApp(auth,email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  useEffect(() => {
    const unsubscribe = observerAuth(auth, async (user) => {
      if (user) {
        console.log("User Login", user)
        const userData = await getUserData(user.uid)
        const { uid, email, displayName } = user;
        setCurrentUser({ uid, email, displayName, company: userData.company });
        console.log("User set", currentUser)
      } else {
        setCurrentUser(null)
      }
        setLoading(false)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    // Esto se ejecutará cuando `currentUser` cambie

}, [currentUser]);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}