import React from "react";
import styles from './Data.module.css'

const Data = ({title, value, unit}) => {
    
    return (
        <div className={styles.container}>
            <h1>{title}</h1>
            <div className={styles.containerchild}>
                <h2>{value}</h2>
                <h3>{unit}</h3> 
            </div>
            

        </div>
    )
} 

export default Data;