import React, { useRef, useState } from "react"
import { Link } from "react-router-dom"
import { useAuth } from "../../../context/AuthContext"
import styles from "../style.module.css"
import Layout from "../../Layout"

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }

  return (
    <Layout>
      <div className={styles.container}>
      <div className={styles.card}>  
          <h2 className={styles.title}>Password Reset</h2>
          {error && <span className={styles.error}>{error}</span>}
          {message && <span className={styles.success}>{message}</span>}
          <form onSubmit={handleSubmit}>
            <div className={styles.email}>
              <label>Email</label>
              <input type="email" ref={emailRef} required />
            </div>
            <button disabled={loading} className={styles.btn} type="submit">
              Reset Password
            </button>
          </form>
          <div className={styles.login}>
            <Link to="/login" className={styles.link}>Login</Link>
          </div>
        </div>
        <div className={styles.signup}>
          Need an account? <Link to="/signup" className={styles.link}>Sign Up</Link>
        </div>
      </div> 
    </Layout>
  )
}